import React from 'react';
import Layout from '../components/Layout';

import tw, { styled } from 'twin.macro';
import Seo from '../components/SEO';
import { PrimaryButton as PrimaryButtonBase } from '../components/misc/Buttons';
import PricingDataSource from '../common/data/pricing';

const Heading = tw.h1`text-3xl font-bold tracking-tight text-primary-700 dark:text-white`;
const Title = tw.h2`block max-w-2xl mt-4 text-xl text-gray-500 dark:text-gray-300`;
const PlansContainer = tw.div`mt-16 flex flex-col items-center lg:flex-row lg:items-stretch lg:justify-between text-gray-900 font-medium`;
const Plan = styled.div`
  ${tw`w-full max-w-sm bg-white rounded-lg shadow-sm py-10 px-6 sm:px-10 lg:px-6 lg:py-10 xl:p-10 mx-3 flex flex-col justify-between mt-16 first:mt-0 lg:mt-0 shadow-raised`}
`;

const PlanHeader = styled.div`
  .nameAndFeaturedContainer {
    ${tw`flex flex-wrap flex-col sm:flex-row justify-between items-center`}
  }
  .name {
    ${tw`lg:text-lg xl:text-xl font-bold uppercase tracking-wider mr-3`}
  }
  .featuredText {
    ${tw`text-xs font-bold px-3 rounded py-2 uppercase bg-green-300 text-green-900 leading-none mt-4 sm:mt-0 w-full sm:w-auto text-center`}
  }
  .pricingContainer {
    ${tw`mt-6 flex items-end justify-between`}
    .currentPrice {
      ${tw`text-lg font-bold leading-none`}
      .bigText {
        ${tw`text-3xl font-bold`}
      }
    }
    .oldPrice {
      ${tw`text-gray-500 text-lg line-through hidden sm:block`}
    }
  }
  .description {
    ${tw`mt-8 font-medium text-gray-700 lg:text-sm xl:text-base`}
  }
`;
const PlanAction = tw.div`mt-4`;

const ActionButton = styled(PrimaryButtonBase)`
  ${tw`block text-center text-sm font-semibold tracking-wider w-full text-gray-100 bg-primary-500 px-6 py-4 rounded hover:bg-primary-700 focus:shadow-outline focus:outline-none transition-colors duration-300`}
`;

const BuyNow = ({
  heading = 'Pricing',
  title = <>Affordable Pricing-Tax filing starting at $24.99.</>,
}) => {
  const { children } = PricingDataSource;

  // const { page } = useStaticQuery(contactQuery);
  return (
    <Layout>
      <Seo title="Pricing" description="Buy the affordable Countmycents individual products." />

      <section tw="bg-white dark:bg-gray-900 mt-16">
        <div tw="container flex flex-col items-center px-4 py-12 mx-auto text-center">
          <Heading>{heading}</Heading>
          <Title>{title}</Title>

          <div tw="mt-6">
            <PlansContainer>
              {children.map((plan) => (
                <Plan key={plan.name} featured={plan.featured}>
                  <PlanHeader>
                    <span className="nameAndFeaturedContainer">
                      <span className="name">{plan.name}</span>
                      {plan.featured && <span className="featuredText">{plan.featured}</span>}
                    </span>
                    <div className="pricingContainer">
                      <span className="currentPrice">
                        <span className="bigText">{plan.price[0]}</span>
                        {plan.price[1]}{' '}
                      </span>
                      {plan.oldPrice && <span className="oldPrice">{plan.oldPrice}</span>}
                    </div>
                    <p className="description">{plan.description}</p>
                  </PlanHeader>
                  <PlanAction>
                    <ActionButton as="a" href={plan.url}>
                      Pay Now
                    </ActionButton>
                  </PlanAction>
                </Plan>
              ))}
            </PlansContainer>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default BuyNow;
