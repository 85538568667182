const PricingDataSource = {
  SectionHeadingBase: '',
  heading: 'Affordable Pricing',
  description: 'Tax filing starting at $24.99',
  plans: null,
  primaryButtonText: 'Buy Now',
  children: [
    {
      name: 'Starter',
      price: ['$24', '.99/Return'],
      oldPrice: '$39.99',
      description: 'Perfect when you have no income but want to get benefits.',
      title: 'You get with Starter:',
      features: ['Contactless', 'Book Appointment', 'Slips:None'],
      url: 'https://buy.stripe.com/00g9CJbSL6LP6eQfZ1',
    },
    {
      name: 'Lite',
      price: ['$49', '.99/Return'],
      oldPrice: '$69.99',
      description: 'Perfect when you have only a few slips but pay the full cost tax filing fee.',
      title: 'Everything in Starter, plus: ',
      features: ['Slips: T4, T4E, T5007,Rent Slip'],
      url: 'https://buy.stripe.com/cN22ah1e7gmpdHiaEG',
    },
    {
      name: 'Basic',
      price: ['$79', '.99/Return'],
      oldPrice: '$99.99',
      description: 'Perfect when you have a complex tax situation and want tax expert help.',
      title: 'Everything in Lite, plus: ',
      slips: ['RRSP', 'Medical Expense', 'Charitable Donations'],
      features: ['You can Dropoff', 'Slips: RRSP, T4A, Medical Expense, Charitable Donations'],
      url: 'https://buy.stripe.com/eVa7uB6yreehbzaaEF',
      featured: 'Most Popular',
    },
    {
      name: 'Premium',
      price: ['$149', '.99/Return'],
      oldPrice: '$189.99',
      description:
        'Perfect when you want tax expert advice and do not want to leave the comfort of your home.',
      title: 'Everything in Standard, plus: ',
      slips: [],
      features: [
        'We Pickup and Dropoff',
        'Slips: T5, T2202A, T4A(OAS), T4A(P), T4RSP, RRIF, T3, Moving Expense',
      ],
      url: 'https://buy.stripe.com/dR69CJ6yrdad32E5kk',
    },
  ],
};
export default PricingDataSource;
